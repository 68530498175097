//====================================================
//  Function: Quantity input
//====================================================
function componentQuantityInput() {
  function init() {
    var quantityItems = document.querySelectorAll('.js-quantity:not(.i-quantity)')
    if (quantityItems.length) {
      quantityItems.forEach((quantity) => {
        quantity.classList.add('i-quantity')

        var inst = quantity,
          newVal = 0,
          input = inst.querySelector('.js-quantity-input'),
          quantityUp = inst.querySelector('.js-quantity-increment'),
          quantityDown = inst.querySelector('.js-quantity-decrement'),
          min = input.getAttribute('min'),
          max = input.getAttribute('max'),
          target = input.dataset.target;

        quantityUp.addEventListener(
          'click',
          function (el) {
            var oldValue = parseFloat(input.value)
            if (max !== null) {
              if (oldValue >= max) {
                newVal = oldValue
              } else {
                newVal = oldValue + 1
              }
            } else {
              newVal = oldValue + 1
            }
            inst.querySelector('.js-quantity-input').value = newVal
            if (target) {
              document.getElementById(target).querySelector('.js-quantity-input').value = newVal
            }
            var event = document.createEvent('HTMLEvents')
            event.initEvent('change', true, false)
            inst.querySelector('.js-quantity-input').dispatchEvent(event)
          },
          false,
        )
        quantityDown.addEventListener(
          'click',
          function (el) {
            var oldValue = parseFloat(input.value)
            if (oldValue <= min) {
              newVal = oldValue
            } else {
              newVal = oldValue - 1
            }
            inst.querySelector('.js-quantity-input').value = newVal
            if (target) {
              document.getElementById(target).querySelector('.js-quantity-input').value = newVal
            }
            var event = document.createEvent('HTMLEvents')
            event.initEvent('change', true, false)
            inst.querySelector('.js-quantity-input').dispatchEvent(event)
          },
          false,
        )
      })
    }
  }

  init()
  body.addEventListener('snippet-update', init)
}
